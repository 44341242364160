<template lang="pug">
	article#termoDeUso
		section#termo
			.wrapper
				div
					h2 Termo de uso
					div(v-html="termo.secoes.termos.conteudo").conteudo
</template>

<script>
export default {
	data() {
		return {
			termo: null,
		}
	},
	created() {
		this.loadtermos()
	},
	methods: {
		loadtermos() {
			this.$axios
				.get(`api/termos-de-uso`)
				.then(response => {
					this.termo = response.data;
				})
		},
	},
}
</script>

<style lang="stylus" scoped src="./TermoDeUso.styl">
</style>